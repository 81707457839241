import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["select_input", "business_amount_div"]

  select() {

   if (this.select_inputTarget.value == "I want a business auto loan or lease" || this.select_inputTarget.value == "I want a business loan or credit card") {
     this.business_amount_divTarget.classList.replace("hidden", "block")
    } else {
      this.business_amount_divTarget.classList.replace("block", "hidden")
    }

  }
}
