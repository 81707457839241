import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["border", "button", "field"]

  toggle() {
    // Enable Hidden Field
    const hidden_field_value = this.fieldTarget.value    

    if (hidden_field_value == "true") {
      this.fieldTarget.value = "false"
    } else {
      this.fieldTarget.value = "true"
    }
    
    // Toggle Button
    this.buttonTarget.classList.toggle("bg-gray-200")
    this.buttonTarget.classList.toggle("bg-primary-box")
    this.buttonTarget.querySelector("span").classList.toggle("translate-x-0")
    this.buttonTarget.querySelector("span").classList.toggle("translate-x-5") 

    // Toggle Border
    this.borderTarget.classList.toggle("border-dotted")
    this.borderTarget.classList.toggle("border-gray-200")
    this.borderTarget.classList.toggle("border-solid")
    this.borderTarget.classList.toggle("border-primary")   
  }
}
