import {Controller} from "@hotwired/stimulus"

export default class extends Controller {
    static targets = ["card-border", "select_input", "div", "button", "field", "card_other_div", "remove"]

    toggle() {
        // Enable Hidden Field
        const hidden_field_value = this.fieldTarget.value

        if (hidden_field_value == "true") {
            this.fieldTarget.value = "false"
        } else {
            this.fieldTarget.value = "true"
        }

        // Toggle Button
        this.buttonTarget.classList.toggle("bg-gray-200")
        this.buttonTarget.classList.toggle("bg-primary-box")
        this.buttonTarget.querySelector("span").classList.toggle("translate-x-0")
        this.buttonTarget.querySelector("span").classList.toggle("translate-x-5")

        // Toggle Div Content
        this.divTarget.classList.toggle("hidden")
    }
    choose() {
        if (this.select_inputTarget.value == 'Other'){
            this.card_other_divTarget.classList.replace("hidden", "block")
        } else {
            this.card_other_divTarget.classList.replace("block", "hidden")
        }
    }

    click() {
        let currentUrl = window.location.href;
        let broker_id = currentUrl.split('/brokers/')[1].split('/cardholder/')[0]
        let request_id = currentUrl.split('/requests/')[1].split('/card')[0]
        let newBlockCard = document.querySelector(".clone_divs")
        let qty_card = jQuery('.card-information').length

        jQuery.ajax({
            type: 'POST',
            dataType: 'script',
            url: '/brokers/' + broker_id + '/cardholder/requests/' + request_id + '/add_new_card',
            data: {qty_card: qty_card},
            success: function (data) {
                var div = document.createElement('div');
                div.classList.add("new-card");
                div.innerHTML = data;

                newBlockCard.appendChild(div)
            },
            error: function (xhr, ajaxOptions, thrownError) {
                alert("I'm sorry but I had a problem. Please try again.");
            }
        });
    }

    remove() {
        this.removeTarget.parentNode.parentNode.remove()
    }
}