import {Controller} from "@hotwired/stimulus"

export default class extends Controller {
    static targets = ["select_state_province", "select_like_receive_refund", "select_like_pay_service", "select_consultant"]

    select() {
        if (this.select_state_provinceTarget.value === '') {
            this.select_state_provinceTarget.classList.add("active")
        } else {
            this.select_state_provinceTarget.classList.remove('active');
        }

        if (this.select_like_receive_refundTarget.value === '') {
            this.select_like_receive_refundTarget.classList.add("active")
        } else {
            this.select_like_receive_refundTarget.classList.remove('active');
        }

        if (this.select_like_pay_serviceTarget.value === '') {
            this.select_like_pay_serviceTarget.classList.add("active")
        } else {
            this.select_like_pay_serviceTarget.classList.remove('active');
        }

        if (this.select_consultantTarget.value === '') {
            this.select_consultantTarget.classList.add("active")
        } else {
            this.select_consultantTarget.classList.remove('active');
        }
    }
}
