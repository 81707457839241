import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["select_input", "zelle_div", "direct_div"]

  select() {  
    if (this.select_inputTarget.value == "Zelle Payment") {
      this.zelle_divTarget.classList.replace("hidden", "block")
      this.direct_divTarget.classList.replace("block", "hidden")
     } else if (this.select_inputTarget.value == "Branch Deposit / Wire") {
      this.zelle_divTarget.classList.replace("block", "hidden")      
      this.direct_divTarget.classList.replace("hidden", "block")      
     }
  }
}
