import {Controller} from "@hotwired/stimulus"

export default class extends Controller {
    static targets = ["select_business_state", "health_insurance_through_marketplace_select", "tax_prep_receive_1095", "receive_1095_select", "select_state_province", "select_like_receive_refund", "select_like_pay_service", "attend_college_tax_year_2023_select", "tax_prep_receive_1098_college_tuition_statement","claiming_children_select", "tax_prep_childs_dependent"]

    initialize() {
        if (this.health_insurance_through_marketplace_selectTarget.value == 'Yes') {
            this.tax_prep_receive_1095Target.classList.add("block") ;
            this.tax_prep_receive_1095Target.classList.remove("hidden") ;
        } else {
            this.tax_prep_receive_1095Target.classList.add("hidden");
            this.tax_prep_receive_1095Target.classList.remove("block") ;
        }

        if (this.attend_college_tax_year_2023_selectTarget.value == 'Yes' ) {
            this.tax_prep_receive_1098_college_tuition_statementTarget.classList.add("block") ;
            this.tax_prep_receive_1098_college_tuition_statementTarget.classList.remove("hidden") ;
        } else {
            this.tax_prep_receive_1098_college_tuition_statementTarget.classList.add("hidden");
            this.tax_prep_receive_1098_college_tuition_statementTarget.classList.remove("block") ;
        }

        if (this.claiming_children_selectTarget.value == 'Yes') {
            this.tax_prep_childs_dependentTarget.classList.add("block");
            this.tax_prep_childs_dependentTarget.classList.remove("hidden");
        }
        else {
            this.tax_prep_childs_dependentTarget.classList.add("hidden");
            this.tax_prep_childs_dependentTarget.classList.remove("block");
        }
    }
    select() {
        if (this.select_business_stateTarget.value === '') {
            this.select_business_stateTarget.classList.add("active")
        } else {
            this.select_business_stateTarget.classList.remove('active');
        }

        if (this.select_state_provinceTarget.value === '') {
            this.select_state_provinceTarget.classList.add("active")
        } else {
            this.select_state_provinceTarget.classList.remove('active');
        }

        if (this.select_like_receive_refundTarget.value === '') {
            this.select_like_receive_refundTarget.classList.add("active")
        } else {
            this.select_like_receive_refundTarget.classList.remove('active');
        }
    }

    change() {
        if (this.health_insurance_through_marketplace_selectTarget.value == 'Yes') {
            this.tax_prep_receive_1095Target.classList.add("block") ;
            this.tax_prep_receive_1095Target.classList.remove("hidden") ;
        } else {
            this.tax_prep_receive_1095Target.classList.add("hidden");
            this.tax_prep_receive_1095Target.classList.remove("block") ;
        }

        if (this.attend_college_tax_year_2023_selectTarget.value == 'Yes') {
            this.tax_prep_receive_1098_college_tuition_statementTarget.classList.add("block") ;
            this.tax_prep_receive_1098_college_tuition_statementTarget.classList.remove("hidden") ;
        } else {
            this.tax_prep_receive_1098_college_tuition_statementTarget.classList.add("hidden");
            this.tax_prep_receive_1098_college_tuition_statementTarget.classList.remove("block") ;
        }

        if (this.claiming_children_selectTarget.value == 'Yes') {
            this.tax_prep_childs_dependentTarget.classList.add("block");
            this.tax_prep_childs_dependentTarget.classList.remove("hidden");
        }
        else {
            this.tax_prep_childs_dependentTarget.classList.add("hidden");
            this.tax_prep_childs_dependentTarget.classList.remove("block");
        }
    }
}
