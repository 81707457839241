import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["standard_button", "standard_button_svg", "express_button", "express_button_svg", "price"]

  select_standard() {
    // Enable Hidden Field
    // const hidden_field_value = this.fieldTarget.value    

    // if (hidden_field_value == "1") {
    //   this.fieldTarget.value = "0"
    // } else {
    //   this.fieldTarget.value = "1"
    // }

    this.express_buttonTarget.classList.remove("ring-2")
    this.express_buttonTarget.classList.remove("ring-current")
    this.express_button_svgTarget.classList.add("hidden")

    this.standard_buttonTarget.classList.add("ring-2")
    this.standard_buttonTarget.classList.add("ring-current")
    this.standard_button_svgTarget.classList.remove("hidden")
  }

  select_express() {
    // Enable Hidden Field
    // const hidden_field_value = this.fieldTarget.value    

    // if (hidden_field_value == "1") {
    //   this.fieldTarget.value = "0"
    // } else {
    //   this.fieldTarget.value = "1"
    // }

    this.standard_buttonTarget.classList.remove("ring-2")
    this.standard_buttonTarget.classList.remove("ring-current")
    this.standard_button_svgTarget.classList.add("hidden")

    this.express_buttonTarget.classList.add("ring-2")
    this.express_buttonTarget.classList.add("ring-current")
    this.express_button_svgTarget.classList.remove("hidden")
  }
}
