import {Controller} from "@hotwired/stimulus"
export default class extends Controller {
    static targets = ["select_input", "referral_first_name_div", "referral_last_name_div", "prefer_contacted_div"]
    select() {
        if (this.select_inputTarget.value == "Yes") {
            this.referral_first_name_divTarget.classList.replace("hidden", "block")
            this.referral_last_name_divTarget.classList.replace("hidden", "block")
            this.prefer_contacted_divTarget.classList.replace("hidden", "block")
        } else {
            this.referral_first_name_divTarget.classList.replace("block", "hidden")
            this.referral_last_name_divTarget.classList.replace("block", "hidden")
            this.prefer_contacted_divTarget.classList.replace("block", "hidden")
        }
    }
}
