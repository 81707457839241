import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["select_input", "form_div", "info_div"]

  select() {

   if (this.select_inputTarget.value == "Dont have one?" || this.select_inputTarget.value == "") {
     this.form_divTarget.classList.replace("block", "hidden")
     this.info_divTarget.classList.replace("hidden", "block")
    } else {
      this.form_divTarget.classList.replace("hidden", "block")
      this.info_divTarget.classList.replace("block", "hidden")
    }

  }
}
