import {Controller} from "@hotwired/stimulus"
export default class extends Controller {
    static targets = ["select_type_assistance", "select_filing_status", "date_of_birth", "next_step_btn", "checkbox_fieldset", "statement_status"]
    initialize() {
        this.statements_status_value = false;
    }
    select() {
        if (this.select_type_assistanceTarget.value === '') {
            this.select_type_assistanceTarget.classList.add("active");
        } else {
            this.select_type_assistanceTarget.classList.remove('active');
        }

        if (this.select_filing_statusTarget.value === '') {
            this.select_filing_statusTarget.classList.add("active")
        } else {
            this.select_filing_statusTarget.classList.remove('active');
        }
    }

    change() {
        let input = document.querySelector('#service_tax_date_of_birth');

        if (!input.value)
            this.date_of_birthTarget.classList.remove('active');
        else
            this.date_of_birthTarget.classList.add("active")

    }

    checked() {
        let checkBoxFieldset = this.checkbox_fieldsetTarget;

        if (checkBoxFieldset.querySelector("input:checked")) {
            this.statements_status_value = true;
            this.statement_statusTarget.checked = true;
        } else {
            this.statements_status_value = false;
            this.statement_statusTarget.checked = false;
        }
    }

    checkBeforeSubmit() {}
}
